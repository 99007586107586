.text {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: block;
    padding: 0 30px;
    font-size: 14px;
    line-height: 1.4;
    transform: translate(0, -50%);
}
