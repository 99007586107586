.box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 49;
}

.boxInner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
}

.preloaderInner :global{
    width: 34px;
    height: 34px;
    animation: 1s loader linear infinite;
}

@keyframes :global(loader) {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
