.questions {
    &__list {
        display: block;
    }

    &__edit {
        display: block;
        margin: -5px 0 50px 0;
    }

    &__add {
        display: block;
    }

    &__delete {
        margin-top: 30px;
        text-align: center;
    }
}

.delete {
    display: block;

    &__message {
        margin: 35px 0 25px 0;
        text-align: center;
    }

    &__footer {
        text-align: center;

        & > * {
            display: inline-block;
        }
    }

    &__action {
        width: auto;
        min-width: 105px;
        margin: 0 10px;
    }
}
