@import '../../styles/mixins';

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 100%;
    height: 100%;
    overflow: none;
    text-align: center;
    pointer-events: none;

    &__description {
        margin: 20px 0 50px;
        color: #676767;
        font-size: 14px;
        text-align: center;
    }

    &__header {
        position: relative;
        z-index: 4;
        display: block;
        width: 100%;
        padding: 10px 50px 10px 50px;
        overflow: hidden;
        text-align: center;
        background-color: #222;

        &--closeable {
            @include respond-to(xs) {
                text-align: left;
            }
        }
    }

    &__title {
        margin: 0;
        padding: 0;
        color: #fff;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.3;

        @include respond-to(xs) {
            margin: 0;
        }
    }

    &__body {
        position: relative;
        z-index: 2;
        padding: 40px 80px 65px 80px;

        @include respond-to(xs) {
            height: calc(100% - 56px);
            padding: 0 15px;
            overflow: auto;
        }
    }

    &--open {
        z-index: 100;
        overflow: auto;
        opacity: 1;
        transition: opacity 0.4s;
        pointer-events: auto;

        @include respond-to(xs) {
            overflow: hidden;
        }

        .content {
            animation-name: anim-open;
            animation-duration: 0.7s;
            animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            animation-fill-mode: forwards;
            pointer-events: auto;

            @include respond-to(xs) {
                overflow-x: hidden;
            }
        }
    }

    &--close {
        opacity: 1;
        transition: opacity 0.4s;

        .content {
            animation-name: anim-close;
            animation-duration: 0.4s;
            animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            animation-fill-mode: forwards;
            pointer-events: auto;
        }
    }
}

.content {
    position: relative;
    z-index: 5;
    display: inline-block;
    width: 550px;
    min-width: 290px;
    max-width: 550px;
    margin: 50px auto;
    overflow: auto;
    overflow-x: hidden;
    text-align: left;
    background-color: #fff;
    border-radius: 0;
    opacity: 0;
    pointer-events: none;

    @include respond-to(xs) {
        height: 100%;
        margin: 0;
        overflow: initial;
        border-radius: 0;
    }

    &__inner {
        position: relative;
        z-index: 1;

        @include respond-to(xs) {
            height: 100%;
            overflow: hidden;
            border-radius: 0;
        }
    }
}

.error {
    text-align: center;

    &__message {
        margin: 35px 0 55px 0;
        text-align: center;
    }

    &__back {
        display: inline-block;
        min-width: 180px;
    }
}

.close {
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 10;
    display: block;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        height: 22px;
        background-color: #fff;
    }

    &::after {
        transform: rotate(45deg) translate(-3px, -2px);
    }

    &::before {
        transform: rotate(-45deg) translate(2px, -3px);
    }
}

@keyframes anim-open {
    0% {
        -webkit-transform: translate3d(0, -400px, 0);
        transform: translate3d(0, -400px, 0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes anim-close {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 100px, 0);
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }
}

@keyframes anim-elem {
    0% {
        -webkit-transform: translate3d(0, -100px, 0);
        transform: translate3d(0, -100px, 0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
