.history {
    display: block;

    &__list {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-collapse: collapse;

        th,
        td {
            padding: 14px 25px;
            line-height: 1.3;
            text-align: left;
            vertical-align: top;

            &:last-of-type {
                width: 140px;
                padding-right: 25px;
                padding-left: 25px;
                text-align: center;
                vertical-align: middle;
            }
        }

        th {
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            background-color: #222;
        }

        td {
            padding: 10px 25px;
            color: #222;
            font-size: 12px;
            border-bottom: 1px solid #e0e0e0;
        }
    }

    &__action {
        padding: 4px 0;
    }
}

.results {
    &__more {
        margin-bottom: 5px;
        line-height: 1.8;

        b {
            font-weight: 900;
        }
    }

    &__total {
        color: #222;
        opacity: 0.5;
    }
}

.delete {
    display: block;

    &__message {
        margin: 35px 0 25px 0;
        text-align: center;
    }

    &__footer {
        text-align: center;

        & > * {
            display: inline-block;
        }
    }

    &__action {
        width: auto;
        min-width: 105px;
        margin: 0 10px;
    }
}
