@import '../../styles/mixins';
@import '../../styles/vars';

.inputContainer {
    position: relative;
    width: 100%;
    margin-top: 15px;

    & + .inputContainer {
        margin-top: 15px;
    }

    &:last-child {
        margin-bottom: 30px;
    }
}

.label {
    font-size: $label_fs;
    font-family: 'Noto Sans', sans-serif;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
    transition: color $t_d_d;

    &:hover {
        color: #6fc9fc;
    }
}

.input {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;
}

.inputContainerError {
    .input {
        color: #bd2020;
        border: 1px solid #bd2020;

        @include placeholder {
            /* Typography */
            color: #bd2020;
        }
    }
}

.loaded {
    margin: 15px 0 30px 0;

    &__image {
        display: inline-block;
        height: 24px;
        vertical-align: middle;
    }

    &__name {
        display: inline-block;
        margin-left: 10px;
        line-height: 24px;
        vertical-align: middle;
    }

    &__reset {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            height: 10px;
            background-color: #222;
            opacity: 0.6;
        }

        &::after {
            transform: rotate(45deg) translate(10px, -5px);
        }

        &::before {
            transform: rotate(-45deg) translate(5px, 10px);
        }
    }
}