@import '../../styles/mixins';
@import '../../styles/vars';
$hz_pd_option: 15px;

.selectContainer {
    width: 100%;
    margin-bottom: 15px;

    & + .selectContainer {
        margin-top: 15px;
    }

    &:last-child {
        margin-bottom: 30px;
    }
}

.label {
    font-size: $label_fs;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.5;
}

.arrow {
    transform: rotate(0deg);
    transition: transform $t_d_d;

    svg {
        display: block;
        margin-top: 2px;
    }

    svg > g > g {
        fill: currentColor;
    }
}

.arrowUp {
    transform: rotate(180deg);
}

.select {
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: border $t_d_d, color $t_d_d;

    &:hover {
        color: rgba(0, 0, 0, 1);
        border: 1px solid transparent;

        @include placeholder {
            /* Typography */
            color: rgba(0, 0, 0, 1);
            transition: color $t_d_d;
        }

        :global(.react-select__placeholder),
        :global(.react-select__single-value) {
            color: rgba(0, 0, 0, 1);
        }

        :global(.react-select__indicator) {
            color: rgba(0, 0, 0, 0.5);
        }

        :global(.react-select__control) {
            border: 1px solid #dcdcdc;
        }

        :global(.react-select__control--is-focused) {
            border: 1px solid rgba(0, 0, 0, 0.5);
        }
    }

    &:focus {
        color: rgba(0, 0, 0, 1);

        @include placeholder {
            /* Typography */
            color: rgba(0, 0, 0, 1);
            transition: color $t_d_d;
        }
    }

    &:disabled {
        color: rgba(0, 0, 0, 0.2);
        background-color: transparent;

        @include placeholder {
            /* Typography */
            color: rgba(0, 0, 0, 0.2);
            transition: color $t_d_d;
        }
    }

    :global(.react-select__control) {
        min-height: 40px;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        box-shadow: none;
        transition: border $t_d_d, color $t_d_d;

        &:hover {
            border: 1px solid #6fc9fc;
        }
    }

    :global(.react-select__indicator) {
        color: rgba(0, 0, 0, 0.5);
    }

    :global(.react-select__control--is-focused) {
        min-height: 40px;
        color: rgba(0, 0, 0, 1);
        border: 1px solid rgba(0, 0, 0, 0.5);

        :global(.react-select__placeholder),
        :global(.react-select__single-value) {
            color: rgba(0, 0, 0, 1);
        }

        :global(.react-select__indicator) {
            color: rgba(0, 0, 0, 1);
        }
    }

    :global(.react-select__value-container) {
        height: 40px;
        padding-left: 15px;

        /* Typography */
        color: rgba(0, 0, 0, 0.5);
        font-weight: 400;
        font-size: 12px;
        font-family: 'Noto Sans', sans-serif;
        line-height: 1.2;
    }

    :global(.react-select__placeholder),
    :global(.react-select__single-value) {
        width: 100%;
        max-width: calc(100% - 30px);
        margin-left: 0;

        /* Typography */
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
        font-size: 12px;
        font-family: 'Noto Sans', sans-serif;
        line-height: 1.2;
        transition: color $t_d_d;
    }

    :global(.react-select__indicator-separator) {
        display: none;
    }

    @include placeholder {
        /* Typography */
        color: rgba(0, 0, 0, 0.5);
        transition: color $t_d_d;
    }

    :global(.react-select__option) {
        position: relative;
        padding: 11px $hz_pd_option;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        font-family: 'Noto Sans', sans-serif;
        background-color: #fff;
        cursor: pointer;
        transition: color $t_d_d;

        &:hover,
        &:focus,
        &:active {
            color: rgba(0, 0, 0, 1);
            background-color: #f7fcff;
        }
    }

    :global(.react-select__option--is-focused) {
        color: rgba(0, 0, 0, 1);
        background-color: #f7fcff;
    }

    :global(.react-select__option--is-selected) {
        color: rgba(0, 0, 0, 1);
    }

    :global(.react-select__menu) {
        left: 0;
        width: 100%;
        margin-top: 0;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    }

    :global(.react-select__menu-list) {
        padding: 0;
    }

    :global(.react-select__control--is-disabled) {
        background-color: #fff;

        :global(.react-select__placeholder),
        :global(.react-select__single-value) {
            /* Typography */
            color: rgba(0, 0, 0, 0.2);
        }
    }
}

:global(.react-select__value-container),
:global(.react-select__indicators),
:global(.react-select__control) {
    cursor: pointer;
}

.select--searchable {
    :global(.react-select__value-container),
    :global(.react-select__control) {
        cursor: text;
    }
}

.selectErrorText {
    /* Box Model */
    margin-top: 15px;

    /* Typography */
    color: #bd2020;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.2;
    text-align: left;
}

.selectContainerError {
    select {
        color: #bd2020;
        border-color: #bd2020;

        @include placeholder {
            /* Typography */
            color: #bd2020;
        }
    }

    :global(.react-select__indicator) {
        color: #bd2020;
    }

    :global(.react-select__control) {
        border-color: #bd2020;
    }

    :global(.react-select__placeholder) {
        color: #bd2020;
    }
}

.selectError {
    border-color: #bd2020;

    :global(.react-select__indicator) {
        color: #bd2020;
    }

    &:hover {
        color: #bd2020;
        border-color: #bd2020;

        :global(.react-select__placeholder),
        :global(.react-select__single-value) {
            color: #bd2020;
        }

        :global(.react-select__control) {
            border-color: #bd2020;
        }

        :global(.react-select__indicator) {
            color: #bd2020;
        }
    }
}
