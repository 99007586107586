.header {
    margin-bottom: 30px;

    &__title {
        color: #ef3125;
        font-weight: bold;
        font-size: 36px;
        line-height: 49px;
    }

    &__action {
        float: right;
    }
}
