@import '../../styles/mixins';
@import '../../styles/vars';

.buttonContainer {
    /* Box Model */
    margin-top: 30px;
}

.buttonDefault {
    /* Box Model */
    display: inline-block;
    width: 100%;
    padding: 12px;

    /* Typography */
    font-weight: 300;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.3333;
    text-align: center;
    text-decoration: none;

    /* Visual */
    border: 1px solid transparent;
    border-radius: 2px;
    outline: none;

    /* Animation */
    cursor: pointer;

    &::before {
        content: none;
    }
}

.buttonRed {
    /* Typography */
    color: #fff;
    background-color: #ef3125;

    /* Animation */
    transition: background-color $t_d_d;

    &:hover {
        /* Visual */
        background-color: #ff0f00;
    }

    &:active {
        /* Visual */
        background-color: #c92318;
    }

    &:disabled {
        /* Visual */
        background-color: #ef3125;
        cursor: default;
        opacity: 0.5;
    }
}

.buttonBlue {
    /* Typography */
    color: #fff;
    background-color: #4bbec8;

    /* Animation */
    transition: background-color $t_d_d;

    &:hover {
        /* Visual */
        background-color: #3ad8e6;
    }

    &:active {
        /* Visual */
        background-color: #2b99a3;
    }

    &:disabled {
        /* Visual */
        background-color: #4bbec8;
        cursor: default;
        opacity: 0.5;
    }
}

.buttonWhite {
    /* Typography */
    color: #222;
    background-color: #fff;
    border-color: #dcdcdc;

    /* Animation */
    transition: background-color $t_d_d, border-color $t_d_d;

    &:hover {
        /* Visual */
        background-color: #f2f2f2;
        border-color: #222;
    }

    &:active {
        /* Visual */
        background-color: #f2f2f2;
        border-color: #222;
    }

    &:disabled {
        /* Visual */
        background-color: #fff;
        border-color: #222;
        cursor: default;
        opacity: 0.5;
    }
}

.buttonText {
    display: inline-block;
    width: auto;
    padding: 0;

    /* Typography */
    color: #000;
    text-decoration: underline;
    background-color: transparent;
    border: unset;
    opacity: 0.5;

    /* Animation */
    transition: opacity $t_d_d;

    &:hover {
        /* Visual */
        opacity: 1;
    }

    &:active {
        /* Visual */
        opacity: 1;
    }

    &:disabled {
        /* Visual */
        background-color: #fff;
        border-color: #222;
        cursor: default;
        opacity: 0.2;
    }
}
