.position {
    margin-top: -10px;
    margin-bottom: 50px;
}

.loaded {
    margin: 15px 0 30px 0;

    &__image {
        display: inline-block;
        height: 24px;
        vertical-align: middle;
    }

    &__name {
        display: inline-block;
        margin-left: 10px;
        line-height: 24px;
        vertical-align: middle;
    }

    &__reset {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            height: 10px;
            background-color: #222;
            opacity: 0.6;
        }

        &::after {
            transform: rotate(45deg) translate(10px, -5px);
        }

        &::before {
            transform: rotate(-45deg) translate(5px, 10px);
        }
    }
}
