.label {
    position: relative;
    display: block;
    padding-left: 26px;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        margin-top: -10px;
        border: 1px solid #dcdcdc;
        border-radius: 100%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 4px;
        width: 10px;
        height: 10px;
        margin-top: -6px;
        background-color: #dcdcdc;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.3s;
    }
}

.input {
    position: absolute;
    left: -9999px;
    display: block;
    width: 0;
    height: 0;

    &:checked {
        & + .label {
            &::after {
                opacity: 1;
            }
        }
    }
}
