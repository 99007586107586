@import '../../styles/mixins';
@import '../../styles/vars';

.container {
    /* Typography */
    text-align: left;

    @include respond-to(md) {
        margin-top: 80px;
        text-align: center;
    }
}

.menuContainer {
    /* Box Model */
    display: inline-block;
    margin-left: 60px;
    vertical-align: middle;

    @include respond-to(md) {
        width: 100%;
        margin-left: 0;
    }
}

.menuLink {
    /* Positioning */
    position: relative;

    /* Box Model */
    display: inline-block;
    width: 100%;
    margin: 0;
    text-align: left;

    @include respond-to(md) {
        text-align: center;
    }

    &[data-rh] {
        @include respond-to(md) {
            pointer-events: none;
        }
    }

    span {
        position: relative;
        display: inline;
    }

    a {
        /* Box Model */
        display: inline-block;
        width: 100%;
        padding: 10px 0;

        /* Typography */
        color: #000;
        font-size: 16px;
        font-family: 'Noto Sans', sans-serif;
        text-decoration: none;

        /* Animation */
        transition: color $t_d_d;

        &::before {
            content: none;
        }

        &:hover,
        &:active,
        &:focus {
            color: #ef3125;
        }

        @include respond-to(md) {
            width: 100%;
        }
    }

    @include respond-to(md) {
        width: 100%;
        margin: 0;
    }
}

a.activeMenuLink {
    position: relative;

    /* Typography */
    color: #ef3125;
    text-decoration: none;

    &:hover {
        color: #ef3125;
    }

    span {
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: calc(100% + 10px);
            display: block;
            width: 250px;
            height: 2px;
            background-color: #ef3125;
        }
    }
}

a.linkDisabled {
    color: #484848;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
}