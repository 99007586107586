@import '../../styles/mixins';
@import '../../styles/vars';

.inputContainer {
    width: 100%;

    & + .inputContainer {
        margin-top: 15px;
    }

    &:last-child {
        margin-bottom: 30px;
    }
}

.label {
    font-size: $label_fs;
    font-family: 'Noto Sans', sans-serif;
    line-height: 30px;
    cursor: pointer;
}

.input {
    width: 100%;
    padding: 5px 10px;
    color: #222;
    font-size: 12px;
    line-height: 28px;
    background-color: #fff;
    border: none;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    transition: border $t_d_d, color $t_d_d, background-color $t_d_d;

    @include placeholder {
        /* Typography */
        color: #dcdcdc;
        font-size: 12px;
        transition: color $t_d_d;
    }

    &:hover {
        border: 1px solid #6fc9fc;
    }

    &:focus,
    &:active {
        color: #222;
        background-color: #fff;
        border: 1px solid rgba(34, 34, 34, 0.5);
    }

    &:disabled {
        color: rgba(0, 0, 0, 0.2);
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.1);

        @include placeholder {
            /* Typography */
            color: rgba(0, 0, 0, 0.2);
            transition: color $t_d_d;
        }
    }
}

.inputContainerError {
    .input {
        color: #bd2020;
        border: 1px solid #bd2020;

        @include placeholder {
            /* Typography */
            color: #bd2020;
        }
    }
}
