@import '../../styles/mixins';

.content {
    text-align: center;

    form {
        position: relative;
        max-width: 200px;
        margin: 0 auto;
        padding: 10px;
    }

    @include respond-to(sm) {
        min-height: 40em;
    }
}

.contentInner {
    /* Positioning */
    position: relative;
}

.content > div {
    /* Box Model */
    display: inline-block;
    width: 100%;
    max-width: 430px;

    /* Typography */
    font-size: 0;
    vertical-align: middle;

    /* Visual */
    background-color: #fff;

    @include respond-to(sm) {
        width: 95%;
        padding: 25px;
    }
}

.content h1 {
    /* Box Model */
    margin-bottom: 25px;

    /* Typography */
    font-size: 36px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.2;

    @include respond-to(sm) {
        font-size: 20px;
    }
}

.content::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 100%;
    min-height: inherit;
    vertical-align: middle;
}
