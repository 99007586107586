$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1300px;

@mixin respond-to($media) {
    @if $media==xs {
        @media only screen and (max-width: $xs) {
            @content;
        }
    }
    @else if $media==sm {
        @media only screen and (max-width: $sm) {
            @content;
        }
    }
    @else if $media==md {
        @media only screen and (max-width: $md) {
            @content;
        }
    }
    @else if $media==lg {
        @media only screen and (max-width: $lg) {
            @content;
        }
    }
    @else if $media==xl {
        @media only screen and (max-width: $xl) {
            @content;
        }
    }
}

@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin clearfix {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}
