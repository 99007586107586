@import '../../styles/mixins';
@import '../../styles/vars';

.wrapper {
    margin: 135px 25px 45px 25px;
}

.userInput,
.dateInput {
    position: relative;
}

.dateInput {
    :global(.react-datepicker-wrapper),
    :global(.react-datepicker__input-container) {
        width: 100%;
    }
}

.subLabel {
    position: absolute;
    top: 8px;
    right: 0;
    color: rgba(133, 111, 38, 0.7);
    font-size: $label_fs;
    font-family: 'Noto Sans', sans-serif;
}

.backContainer {
    height: 100vh;
}

.backLink {
    display: block;
    height: 100%;
    background-color: #ffc500;
    transition: background-color $t_d_d;

    &Inner {
        display: inline-block;
        width: 100%;
        text-align: center;
        vertical-align: middle;
    }

    &Text {
        position: relative;
        display: inline-block;
        color: #222;
        font-size: 22px;
        font-family: 'Noto Sans', sans-serif;
    }

    &Subtext {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-family: 'Noto Sans', sans-serif;
    }

    &Icon {
        position: absolute;
        top: -1px;
        left: -24px;
        transform: rotate(90deg);
    }

    &MobileText {
        position: absolute;
        bottom: 25px;
        display: none;
        width: 100%;
        color: #000;
        font-size: 14px;
        line-height: 17px;
        text-align: center;

        @include respond-to(md) {
            display: block;
        }
    }

    &::after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    &:hover {
        background-color: #ffd64c;
    }
}

.checkboxContainer {
    margin-top: 30px;
}
