@import '../styles/mixins';
@import '../styles/vars';

.inputErrorText {
    /* Box Model */
    margin-top: 5px;

    /* Typography */
    color: #bd2020;
    font-size: 10px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.2;
    text-align: center;
}

.pageContainer {
    max-height: 100vh;
    padding: 40px 35px 20px 35px;
    overflow-x: hidden;
    overflow-y: auto;

    @include respond-to(md) {
        padding: 20px;
    }
}

.pageContainerPagination {
    height: 100vh;
    padding: 40px 35px 20px 35px;
    overflow-x: hidden;
    overflow-y: auto;

    @include respond-to(md) {
        height: auto;
        min-height: calc(100vh - 60px);
        padding: 20px 20px 80px 20px;
    }
}

.top {
    h1 {
        // float: left;
        color: #222;
        font-size: 36px;
        font-family: 'Noto Sans', sans-serif;

        @include respond-to(md) {
            text-align: center;
        }
    }

    h3 {
        margin-top: 10px;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Noto Sans', sans-serif;
    }

    @include clearfix;
}

.topSelect {
    float: right;
    width: 100%;
    max-width: 150px;
}

.dataBlock {
    display: inline-block;
    width: 100%;
    margin-bottom: 68px;
    vertical-align: top;

    &Half {
        width: 50%;
    }
}

.dataTitle {
    margin-bottom: 30px;
    color: rgba(34, 34, 34, 0.3);
    font-size: 20px;
    font-family: 'Noto Sans', sans-serif;

    @include respond-to(md) {
        text-align: center;
    }
}

.dataTable {
    display: inline-block;
    width: 50%;
    min-width: 360px;
    padding-right: 20px;

    &Full {
        width: 100%;
    }

    @include respond-to(md) {
        min-width: auto;
        padding-right: 0;
    }

    table,
    tbody {
        width: 100%;
        min-width: 360px;

        @include respond-to(md) {
            min-width: auto;
        }
    }

    tr > td {
        width: 50%;
        color: #222;
        font-size: 16px;
        line-height: 28px;
        transition: color $t_d_d;

        &:first-child {
            min-width: 160px;
            font-family: 'Noto Sans', sans-serif;
        }

        &:last-child {
            min-width: 190px;
            font-family: 'Noto Sans', sans-serif;
        }

        &[data-rh] {
            // text-decoration: underline;
            // cursor: pointer;
            text-decoration: unset;
            cursor: default;

            &:hover {
                // color: #217dc0;
            }
        }

        @include respond-to(md) {
            display: inline-block;
            width: 100%;
        }
    }
}

.isLoad {
    position: relative;
    height: 60vh;
}
