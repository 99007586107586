@import '../../styles/vars';

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contentContainer {
    /* Box Model */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1 0 auto;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0 !important;
}

input,
textarea,
select,
button {
    font-size: 16px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.2;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

body {
    /* Typography */
    font-size: 14px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.2;

    /* Visual */
    background-color: #fff;
    background-size: cover;
}

body::after {
    content: '';

    /* Positioning */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 51;

    /* Box Model */
    width: 100%;
    height: 100%;
    overflow: unset;

    /* Visual */
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

    /* Animation */
    transition: opacity 0.4s 0.1s;

    /* Misc */
    pointer-events: none;
}

:global(body.dialog-overlay:after) {
    opacity: 1;
}

a {
    position: relative;
}

a:focus,
a:hover,
a:active {
    /* Typography */
    color: rgba(0, 0, 0, 0.9);
    text-decoration: none;
}

:global(.react-hint) {
    position: absolute;
    z-index: 9999;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1;
    cursor: default;
    animation: $t_d_d fadeIn;

    &__content {
        padding: 5px 10px;
        color: #222;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }
}

.appFadeOutIn :global{
    animation: 2s fadeOutIn;
}

@keyframes :global(fadeOutIn) {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 0;
    }

	60% {
		opacity: 0;
    }
    
    100% {
        opacity: 1;
    }
}
