@import '../../styles/mixins';

$width_sidebar: 480px;
$duration_effect: 0.5s;
$mobile_header_height: 60px;

.container {
    display: flex;
    font-size: 0;
}

:global(.App--safari) {
    .container {
        position: relative;
        overflow: hidden;
    }
}

.sideBar {
    position: relative;
    display: flex;
    min-height: 100vh;
    font-size: 0;
    vertical-align: top;
    transform: translateX(-$width_sidebar);
    transition: transform $duration_effect;

    @include respond-to(md) {
        position: absolute;
        left: -100vw;
        z-index: 100;
        transform: translateX(0);
        transition: left $duration_effect;
    }

    .menu {
        position: relative;
        z-index: 10;
        overflow: hidden;
        background-color: #fff;
    }

    .menu,
    .contentSideBar {
        display: inline-block;
        width: 250px;
        vertical-align: top;
        border-right: 1px solid #e3e3e3;

        @include respond-to(md) {
            width: 100vw;
        }
    }

    .contentSideBar {
        font-size: 16px;
        background-color: #fff;
        transform: translateX(-$width_sidebar / 2);
        transition: transform $duration_effect;

        @include respond-to(md) {
            display: none;
        }
    }

    .logo {
        display: inline-block;
        margin: 20px 55px 60px 55px;

        @include respond-to(md) {
            display: none;
        }
    }
}

.content {
    position: relative;
    width: 100%;
    margin-left: -$width_sidebar;
    font-size: 16px;
    vertical-align: top;
    transition: width $duration_effect, margin-left $duration_effect;

    @include respond-to(md) {
        margin-top: $mobile_header_height;
        margin-left: 0;
    }
}

.footerSideBar {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: $width_sidebar / 2;
    padding: 20px 15px 20px 60px;
    background-color: #fff;

    @include respond-to(md) {
        width: 100%;
        padding: 20px;
        text-align: center;
    }
}

.footerSideBarText {
    margin-bottom: 15px;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-family: 'Noto Sans', sans-serif;
    text-overflow: ellipsis;
}

.container.menuIsOpening {
    .sideBar {
        transform: translateX(0);
    }

    .content {
        width: calc(100% - #{$width_sidebar} / 2);
        margin-left: -($width_sidebar / 2);
    }
}

.mobile {
    &Header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 101;
        display: none;
        height: $mobile_header_height;
        background-color: #222;
        border-bottom: 1px solid #222;
        transition: border-bottom $duration_effect;

        @include respond-to(md) {
            display: block;
        }

        &Menu,
        &Filter {
            position: absolute;
            top: 8px;
            padding: 10px;
            background-color: transparent;
            border: unset;
        }

        &Menu {
            left: 10px;
        }

        &Filter {
            right: 10px;
        }

        &Logo {
            max-height: $mobile_header_height;
            padding: 8px 56px;
            text-align: center;
        }

        &Inner {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            font-size: 30px;
        }
    }

    &Filter {
        position: absolute;
        right: -100vw;
        z-index: 99;
        display: none;
        width: 100vw;
        min-height: 100vh;
        background: #ffc500;
        transition: right $duration_effect;

        &IsOpen {
            right: 0;
        }

        @include respond-to(md) {
            display: block;
        }
    }
}

:global(.App--safari) {
    .mobileFilter {
        height: 100%;
    }

    .sideBar {
        @include respond-to(md) {
            height: 100%;
        }
    }

    .footerSideBar {
        @include respond-to(md) {
            bottom: 80px;
        }
    }
}

.container.menuIsOpen {
    @include respond-to(md) {
        overflow: hidden;
    }

    .sideBar {
        transform: translateX(0);

        @include respond-to(md) {
            left: 0;
        }

        .contentSideBar {
            transform: translateX(0);
        }
    }

    .content {
        width: calc(100% - #{$width_sidebar});
        margin-left: 0;

        @include respond-to(md) {
            width: 100vw;
        }
    }

    .mobileHeader {
        @include respond-to(md) {
            border-bottom: 1px solid #fff;
        }
    }
}

.logout {
    width: auto;
    min-width: 75px;
    padding: 5px 10px;
}
