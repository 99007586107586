.accounts {
    display: block;

    &__list {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-collapse: collapse;

        th,
        td {
            padding: 14px 25px;
            line-height: 1.3;

            &:first-child {
                text-align: left;
            }

            &:nth-child(1) {
                width: 60%;
            }
        }

        th {
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            background-color: #222;
        }

        td {
            padding: 10px 25px;
            color: #222;
            font-size: 12px;
            text-align: center;
            border-bottom: 1px solid #e0e0e0;
        }
    }
}

.delete {
    display: block;

    &__message {
        margin: 35px 0 25px 0;
        text-align: center;
    }

    &__footer {
        text-align: center;

        & > * {
            display: inline-block;
        }
    }

    &__action {
        width: auto;
        min-width: 105px;
        margin: 0 10px;
    }
}
